import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Link from "../components/link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import PackageCard from "../components/package-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BeeBanner from "../components/bee-banner";
import { AiFillCheckCircle } from "react-icons/ai";
import SVGHRPackagesBg from "../components/SVG/hr-packages-bg";
import SVGPolygon from "../components/SVG/polygon";

const HrPackages = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "HR Packages",
				item: {
					url: `${siteUrl}/hr-packages`,
					id: `${siteUrl}/hr-packages`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="HR Packages| Hive HR Solutions"
					description="We offer monthly packages, these will suit businesses that like to budget ahead and can expect the same cost every month for their HR support. / placeholder"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/hr-packages`,
						title: "HR Packages | Hive HR Solutions",
						description:
							"We offer monthly packages, these will suit businesses that like to budget ahead and can expect the same cost every month for their HR support. / placeholder",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>

				<section className="pb-5 pt-lg-6 pt-4 position-relative">
					<SVGHRPackagesBg
						style={{ zIndex: -2 }}
						className="position-absolute d-none top-50 translate-middle-y h-auto w-100 d-lg-block "
					/>
					<Container>
						<Row className=" pt-3 justify-content-center ">
							<Col className="text-center" lg={8} xl={7}>
								<div className="position-relative  d-inline-block">
									<SVGPolygon
										className="position-absolute d-none d-lg-block"
										style={{ zIndex: -1, right: "-1.2rem", top: "-1.5rem" }}
									/>
									<h1 className="pt-1 display-5 d-inline-block mb-3">
										HR packages
									</h1>
								</div>

								<hr className="hr-primary mb-4" />
							</Col>
						</Row>

						<Row className="gx-4 justify-content-center">
							{/* <PackageCard
								title="Recruitment support"
								discountSmall
								price={
									<span>
										<p className=" d-inline-block fw-normal">From</p>
										<span className="fs-3 fw-bold me-2"> £50</span>
										<p className=" d-inline-block fw-normal">per hour</p>
									</span>
								}
								body={
									<ul
										style={{ fontSize: "90%" }}
										className="help-list pe-0 ps-1"
									>
										<li className="d-flex align-items-start">
											<AiFillCheckCircle
												style={{ minWidth: "24px" }}
												className="mt-1 me-2 fs-4"
											/>
											<p>Creation of job descriptions/adverts</p>
										</li>
										<li className="d-flex align-items-start">
											<AiFillCheckCircle
												style={{ minWidth: "24px" }}
												className="mt-1 me-2 fs-4"
											/>
											<p>Application screening</p>
										</li>
										<li className="d-flex align-items-start">
											<AiFillCheckCircle
												style={{ minWidth: "24px" }}
												className="mt-1 me-2 fs-4"
											/>
											<p>Creation of interview questions</p>
										</li>
										<li className="d-flex align-items-start">
											<AiFillCheckCircle
												style={{ minWidth: "24px" }}
												className="mt-1 me-2 fs-4"
											/>
											<p>
												Coordinating interviews and managing candidate feedback
											</p>
										</li>
									</ul>
								}
							/> */}
							<PackageCard
								title="Pay as you go"
								price={
									<span>
										<span className="fs-3 fw-bold mx-2">From £120</span>
										<p className=" d-inline-block fw-normal">per hour</p>
									</span>
								}
								body={
									<p className="ps-1">
										This package gives businesses complete flexibility to pay as
										they go for HR support on an ad hoc basis.
									</p>
								}
							/>
							<PackageCard
								title="Blocks of 10 hours"
								noDiscount
								price={
									<span>
										<span className="fs-3 fw-bold me-2">£114</span>
										<p className=" d-inline-block fw-normal">per hour</p>
									</span>
								}
								body={
									<p className="ps-1">
										This package is best suited to businesses that wish to buy
										blocks of time, where they prefer the option of paying
										upfront for their HR support. They may have specific
										projects coming up, or they may simply favour the
										flexibility of buying HR support in this way.
									</p>
								}
							/>
							<PackageCard
								title="Monthly Support"
								price={<p className="fw-normal fs-5">Quotation</p>}
								body={
									<>
										<p>This is tailored to your needs but could include:</p>
										<ul
											style={{ fontSize: "90%" }}
											className="help-list pe-0 ps-1"
										>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													Offers of Employment and Contracts of Employment
												</p>
											</li>

											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													Tailored letters and forms
												</p>
											</li>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													Best practice Handbook and Policy updates
												</p>
											</li>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													Initial review of all HR documentation to ensure
													compliance
												</p>
											</li>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													<Link
														className="link-orange pb-0 mb-0 cta-link"
														to="/hr-software"
													>
														BreatheHR software
													</Link>
												</p>
											</li>
										</ul>
									</>
								}
							/>
							<PackageCard
								title="Recruitment Support"
								price={
									<span className="text-start">
										<p>For roles under £30k our hourly rate would be £75ph</p>
										<p>For roles over £30k our hourly rate would be £120ph</p>
									</span>
								}
								body={
									<>
										<p>
											Are you struggling to find time to recruit for your team,
											with our professional recruitment administration service
											we can save you time.
										</p>
										<ul
											style={{ fontSize: "90%" }}
											className="help-list pe-0 ps-1"
										>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													We can write engaging job adverts and job descriptions
												</p>
											</li>

											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													We can provide advice on where to advertise to reach
													the right applicants
												</p>
											</li>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													We can provide suggestions for assessments to assess
													skills and knowledge
												</p>
											</li>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													We can telephone screen applicants
												</p>
											</li>
											<li className="d-flex align-items-start pb-3">
												{" "}
												<AiFillCheckCircle
													style={{ minWidth: "24px" }}
													className="mt-1 me-2 fs-4"
												/>
												<p className="pb-0 mb-0 pt-2">
													We can provide suitable interview questions linked to
													role competencies and your company values
												</p>
											</li>
										</ul>
									</>
								}
							/>
						</Row>
					</Container>
				</section>
				<section>
					{" "}
					<BeeBanner
						headline="Ready to speak to us?"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default HrPackages;
